/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";

interface Price {
  price: number;
  original_price: number;
  iva: number;
}

interface CheckoutState {
  isLoadingData: boolean;
  lang: string;
  locale: string;
  data: any;
  isTutoring: boolean;
  partitaIva: boolean;
  acceptTerms: boolean;
  userInfo: any;
  newUser: any;
  showPdf: boolean;
  price: Price;
  startMonth: string | null;
  acceptedDiscount: boolean;
  params: Record<string, any>;
  quantity: number;
  route: string;
  isOneToOne: boolean;
  paymentMethod: any;
  installments: any;
  installmentType: any;
  isEasyPeasy: boolean;
  isProcessingPayment: boolean;
  labelPaymentMethod: string;
  vParams: Record<string, any>;


  setIsLoadingData: (value: boolean) => void;
  setLocale: (value: string) => void;
  setData: (value: any) => void;
  setIsTutoring: (value: boolean) => void;
  setLang: (value: string) => void;
  setShowPDF: (value: boolean) => void;
  setPartitaIva: (value: boolean) => void;
  setAcceptTerms: (value: boolean) => void;
  setUserInfo: (value: any) => void;
  setNewUser: (value: any) => void;
  setPrice: (value: Price) => void;
  setParams: (value: Record<string, any>) => void;
  setAcceptedDiscount: (value: boolean) => void;
  setStartMonth: (value: string | null) => void;
  setQuantity: (value: number) => void;
  setRoute: (value: string) => void;
  setIsOneToOne: (value: boolean) => void;
  setPaymentMethod: (value: any) => void;
  setInstallmentType: (value: any) => void;
  setIsEasyPeasy: (value: boolean) => void;
  setIsProcessingPayment: (value: boolean) => void;
  setLabelPaymentMethod: (value: string) => void;
  setInstallments: (value: any) => void;
  setVParams: (value: Record<string, any>) => void;
}

const useCheckoutState = create<CheckoutState>((set) => ({
  isLoadingData: false,
  lang: "it",
  data: null,
  isTutoring: false,
  locale: "it-IT",
  partitaIva: false,
  acceptTerms: false,
  userInfo: null,
  newUser: null,
  showPdf: false,
  price: {
    price: 0,
    original_price: 0,
    iva: 0,
  },
  startMonth: null,
  acceptedDiscount: false,
  params: {},
  quantity: 1,
  route: "/",
  isOneToOne: false,
  paymentMethod: null,
  installments: 0,
  installmentType: null,
  isEasyPeasy: false,
  isProcessingPayment: false,
  labelPaymentMethod: "",
  vParams: {},
  setIsLoadingData: (value: boolean) => set({ isLoadingData: value }),
  setData: (value: any) => set({ data: value }),
  setIsTutoring: (value: boolean) => set({ isTutoring: value }),
  setLocale: (value: string) => set({ locale: value }),
  setLang: (value: string) => set({ lang: value }),
  setShowPDF: (value: boolean) => set({ showPdf: value }),
  setPartitaIva: (value: boolean) => set({ partitaIva: value }),
  setAcceptTerms: (value: boolean) => set({ acceptTerms: value }),
  setUserInfo: (value: any) => set({ userInfo: value }),
  setNewUser: (value: any) => set({ newUser: value }),
  setPrice: (value: Price) => set({ price: value }),
  setParams: (value: Record<string, any>) => set({ params: value }),
  setAcceptedDiscount: (value: boolean) => set({ acceptedDiscount: value }),
  setStartMonth: (value: string | null) => set({ startMonth: value }),
  setQuantity: (value: number) => set({ quantity: value }),
  setRoute: (value: string) => set({ route: value }),
  setIsOneToOne: (value: boolean) => set({ isOneToOne: value }),
  setPaymentMethod: (value: any) => set({ paymentMethod: value }),
  setInstallmentType: (value: any) => set({ installmentType: value }),
  setIsEasyPeasy: (value: boolean) => set({ isEasyPeasy: value }),
  setIsProcessingPayment: (value: boolean) => set({ isProcessingPayment: value }),
  setLabelPaymentMethod: (value: string) => set({ labelPaymentMethod: value }),
  setInstallments: (value: any) => set({ installments: value }),
  setVParams: (value: Record<string, any>) => set({ vParams: value }),
}));

export default useCheckoutState;
