export const getQueryParams = (keys: string[]): Record<string, string> => {
  const query = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};

  if (query) {
    keys.forEach((key) => {
      const value = query.get(key);

      if (value) {
        params[key] = value;
      }
    });
  }

  return params;
};

export const setQueryParam = (key: string, value: string): void => {
  const query = new URLSearchParams(window.location.search);
  query.set(key, value);
  window.history.replaceState({}, "", `${window.location.pathname}?${query}`);
};

export const getQueryParam = (key: string): string | null => {
  const query = new URLSearchParams(window.location.search);
  return query.get(key);
};
