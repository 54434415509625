import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";
import fr from "./locales/fr/translation.json";
import it from "./locales/it/translation.json";
import pt from "./locales/pt/translation.json";
import de from "./locales/de/translation.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["path", "queryString"],
      lookupFromPathIndex: location.pathname.includes('checkout/') ? 2 : 1,
    },
    resources: {
      en: { translation: en },
      es: { translation: es },
      fr: { translation: fr },
      it: { translation: it },
      pt: { translation: pt },
      de: { translation: de },
    },
    supportedLngs: ["en", "es", "fr", "it", "pt", "de"],
  });

export default i18n;

export const baseUrl = i18n.language === "en" ? "" : i18n.language;
