import i18n from "@/utils/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/pt";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import { CheckoutProvider } from "./context/CheckoutContext.tsx";
import "./index.css";

const queryClient = new QueryClient();
const BASE = import.meta.env.EDS_BASE_ROUTE;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(i18n.language);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={BASE}>
        <CheckoutProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </CheckoutProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>,
);
