import LinearProgress from "@/components/LinearProgress.tsx";
import AllRoutes from "@/routes";
// import "@dotlottie/react-player/dist/index.css";
import { Suspense } from "react";

function App() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AllRoutes />
    </Suspense>
  );
}

export default App;
