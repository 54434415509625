import axios from "axios";

const BASE_URL = import.meta.env.EDS_API_BASE_URL;

const Api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default Api;
